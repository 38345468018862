<template>
  <b-card
    bg-variant="light"
    class="text--black"
  >
    <b-overlay :show="loading">
      <b-row>
        <b-col class="text-left">
          <h1>Industry Averages</h1>
        </b-col>
        <b-col class="text-right">
          <router-link
            to="/admin/industry/report/"
            v-slot="{ href, route, navigate}"
            class="mr-4"
          >
            <b-btn
              :href="href"
              @click="navigate"
              variant="success"
              :text="route.name"
            >Generate Report</b-btn>
          </router-link>
          <router-link
            to="/admin/industry/edit/"
            v-slot="{ href, route, navigate}"
          >
            <b-btn
              :href="href"
              @click="navigate"
              variant="primary"
              :text="route.name"
            >Create New</b-btn>
          </router-link>
        </b-col>
      </b-row>

      <b-table
        striped
        hover
        bordered
        small
        :items="averages"
        :fields="fields"
        primary-key="id"
      >
        <template #cell(industryrevenuebucket_id)="data">
          <div v-if="data.item.industryrevenuebucket_id && data.item.industryrevenuebucket_id > 0">
            <span v-if="data.item.bucket_max && data.item.bucket_max > 0">
              {{ data.item.bucket_min | currency(false) }} - {{ data.item.bucket_max | currency(false) }}
            </span>
            <span v-else>
              {{ data.item.bucket_min | currency(false) }}+
            </span>
          </div>
        </template>
        <template #cell(companies)="data">
          {{ data.item.companies }} / {{ getAvailableCompanyCount(data.item.industry_id) }}
        </template>
        <template #cell(created)="data">
          <span class="small">{{ data.item.created }} by {{ data.item.createdby }}</span>
        </template>
        <template #cell(updated)="data">
          <span
            v-if="data.item.updated"
            class="small"
          >{{ data.item.updated }} by {{ data.item.updatedby }}</span>
        </template>
        <template #cell(quarter)="data">
          <span v-if="data.item.quarter">Q{{ data.item.quarter }}</span>
          <span v-else>All</span>
        </template>
        <template #cell(id)="data">
          <b-button-toolbar class="d-block">
            <b-button-group size="sm">
              <b-button
                title="Regenerate Average"
                variant="primary"
                :disabled="loading"
              >
                <b-icon-bootstrap-reboot
                  @click.prevent="regenAvg(data.item)"
                  aria-hidden="true"
                >
                </b-icon-bootstrap-reboot>
              </b-button>

              <b-button
                title="Edit"
                variant="success"
                :disabled="loading"
              >
                <b-icon-pencil-fill
                  title="Edit"
                  @click.prevent="editAvg(data.item.id)"
                  aria-hidden="true"
                ></b-icon-pencil-fill>
              </b-button>

              <b-button
                title="Delete"
                variant="danger"
                :disabled="loading"
              >
                <b-icon-trash-fill
                  title="Delete"
                  @click.prevent="delAvg(data.item.id)"
                  aria-hidden="true"
                ></b-icon-trash-fill>
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </template>
      </b-table>
    </b-overlay>
  </b-card>
</template>
<script>
import IndustryService from '@/services/IndustryService'

export default {
  name: 'IndustryAverageList',
  data () {
    return {
      loading: false,
      industries: null,
      fields: [
        { key: 'industry', sortable: true, thClass: 'text-left', tdClass: 'align-middle text-left' },
        { key: 'year', sortable: true, tdClass: 'align-middle' },
        { key: 'quarter', sortable: true, tdClass: 'align-middle' },
        { key: 'industryrevenuebucket_id', label: 'Revenue Bucket', sortable: true, tdClass: 'align-middle' },
        { key: 'companies', sortable: true, tdClass: 'align-middle' },
        { key: 'records', sortable: true, tdClass: 'align-middle' },
        { key: 'created', sortable: true, tdClass: 'align-middle' },
        { key: 'updated', sortable: true, tdClass: 'align-middle' },
        { key: 'id', label: '', sortable: false, tdClass: 'align-middle text-center' }
      ],
      averages: null
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      this.loading = true
      IndustryService.init().then((response) => {
        if (response.data.result) {
          this.industries = response.data.info.industries
          this.averages = response.data.info.averages
        }
      }).catch((err) => {
        this.$aimNotify.err(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    async delAvg (id) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this record?', { title: 'Delete Record' }).then((val) => {
        if (val) {
          this.loading = true
          IndustryService.delete(id).then((response) => {
            if (response.data.result) {
              this.$aimNotify.notify(response, 'info', 'Record Deleted', 'The record was successfully deleted.')
              this.init()
            } else {
              this.$aimNotify.error(response, 'error', 'Delete Failed', 'An error occurred while deleting the record.')
            }
          }).catch((err) => {
            this.$aimNotify.error(err.response)
          }).finally(() => {
            this.loading = false
          })
        }
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      })
    },

    editAvg (id) {
      if (+id) {
        this.$router.push('/admin/industry/edit/' + +id)
      }
    },

    regenAvg (item) {
      if (this.loading) {
        return false
      }

      this.loading = true
      IndustryService.save(item).then((response) => {
        if (response.data.result) {
          this.$aimNotify.notify(response, 'info', 'Started Regeneration', 'Your Average is being Generated.')
        } else {
          this.$aimNotify.error(response, 'error', 'Generation Failed', 'Failed to Generate Average!')
        }
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    getAvailableCompanyCount (industryId) {
      let c = 0
      if (this.industries && this.industries.length) {
        this.industries.some((i) => {
          if (+i.id === +industryId) {
            return (c = (i.companies) ? i.companies.length : 0)
          } else return false
        })
      }
      return c
    }
  }
}
</script>
